define("discourse/plugins/post-menu-pm-button/discourse/initializers/extend-for-post-menu-pm-button", ["exports", "discourse/lib/plugin-api", "discourse/models/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initPostMenuPmButton(api) {
    api.includePostAttributes("show_pm_user_button");
    const pmButton = {
      action: "sendPmToUser",
      icon: "far-comment-dots",
      title: "post_menu_pm_button.button.title",
      className: "reply create fade-out pm-button-plug-in"
    };
    api.addPostMenuButton("pm-user", attrs => {
      if (attrs.show_pm_user_button) {
        const button = pmButton;
        if (!attrs.mobileView) {
          button.label = "post_menu_pm_button.button.label";
        }
        return button;
      }
    });
    api.addPostMenuButton("reply-2", attrs => {
      if (attrs.canCreatePost) {
        const button = {
          action: "replyToPost",
          title: "post.controls.reply",
          icon: "reply",
          className: "reply create fade-out pm-button-plug-in"
        };
        if (!attrs.mobileView) {
          button.label = "topic.reply.title";
        }
        if (attrs.show_pm_user_button) {
          button.className += " mmn-no-pull";
        }
        return button;
      }
    });
    api.attachWidgetAction("post-menu", "sendPmToUser", function () {
      this.register.lookup("controller:composer").open({
        action: _composer.default.PRIVATE_MESSAGE,
        recipients: this.attrs.username,
        archetypeId: 'private_message',
        draftKey: 'new_private_message',
        reply: window.location.protocol + "//" + window.location.host + this.attrs.shareUrl
      });
    });
  }
  var _default = _exports.default = {
    name: 'extend-for-post-menu-pm-button',
    initialize(_container) {
      (0, _pluginApi.withPluginApi)('0.1', initPostMenuPmButton);
    }
  };
});